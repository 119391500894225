.contactContainer {
  padding-bottom: 10px;
}

.contactForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 80%;
}
