header {
  width: 100%;
}
.logo-image {
  margin-top: 10px;

  padding: 0 20px;
}
.logo-nav {
  width: 100%;
}

@media (min-width: 30em) {
  .logo-nav {
    width: auto;
  }
}
