.desktop {
  visibility: hidden;
}
.mobile {
  font-size: 0.8em;
  width: 100%;
  visibility: unset;
  position: fixed;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  z-index: 20;
  bottom: 4px;
  background: var(--black);
  color: var(--white);
}
nav {
  font-size: 1.2em;
}
nav span {
  border-bottom: 1px solid transparent;
  transition: border-bottom 0.2s ease;
  transition-delay: 0.25s;
  margin: 1em;
  padding-bottom: 0.3em;
}

nav span:hover {
  cursor: pointer;
  border-bottom: 1px solid var(--white);
}

nav span:hover i {
  color: var(--tertiary);
}

nav span.activeClass i {
  color: var(--tertiary);
}

nav span.activeClass {
  border-bottom-color: var(--secondary);
}
@media only screen and (min-width: 760px) {
  .desktop {
    visibility: unset;
    z-index: 10;
    height: 100vh;
    background: var(--brown);
    color: var(--white);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 20px 0;
    position: fixed;
    top: 0px;
    right: 0px;
  }
  .mobile {
    visibility: hidden;
  }
}
