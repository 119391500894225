.intro {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.selfieImg {
  padding: 10px 20px;
}

@media only screen and (min-width: 760px) {
  .intro {
    flex-direction: column;
  }
  .selfieImg {
    width: 300px;
    height: 300px;
    padding: 10px 20px;
  }
}
