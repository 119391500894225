.about_section {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.selfLantern {
  padding-bottom: 20px;
}
.aboutMe {
}
