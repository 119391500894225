.footer {
  background: var(--brown);
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  align-items: center;
  padding: 20px;
}

.socialMedia a {
  color: var(--tertiary);
  margin: 0 1rem;
  transition: transform 250ms;
  padding: 20px;
}
.socialMedia a:hover {
  transform: translateY(-2px);
  color: var(--secondary);
}
.copyright {
  color: var(--primary);
}
@media only screen and (min-width: 760px) {
}
