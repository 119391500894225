.card {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  justify-content: center;
  align-items: center;

  gap: 10px;
  border-radius: 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

  padding: 20px;
  margin: 10px 0;
}
.card .image {
  padding: 10px;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
}
.card > .info {
  padding: 10px;
  align-self: start;
}

@media (min-width: 800px) {
  .card {
    flex-direction: row;
  }
}
