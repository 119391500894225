:root {
  --white: #fffdf4;
  --primary: #e16606;
  --secondary: #35b4c0;
  --brown: #46260f;
  --tertiary: #b02b10;
  --black: #3b2f30ff;
}
/* * {
  border: 1px solid red;
} */
body {
  background: var(--white);
}
.container {
  max-width: 100%;
}

section {
  margin: auto;
  width: 100%;
  min-height: 90vh;
}
button {
  background: var(--primary);
  cursor: pointer;
}
button:hover {
  background: var(--tertiary);
}
button .icon-large {
  color: var(--white);
}
button .icon-large:hover {
  color: var(--secondary);
}
a {
  font-family: 'Urbanist', sans-serif;
  font-size: 1.3em;
  text-decoration: none;
  color: var(--tertiary);
  cursor: pointer;
}
h1,
h2,
h3 {
  /* font-family: 'Dosis', sans-serif; */
  font-family: 'Urbanist', sans-serif;
  color: var(--black);
}
p,
li {
  font-family: 'Figtree', sans-serif;
  color: var(--black);
}

@media only screen and (min-width: 760px) {
  a {
    font-size: 1.5em;
  }
  .container {
    width: 100%;
    padding: 0;
  }
  section {
    width: 70%;
    margin: auto;
  }
}
